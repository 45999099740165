// media query
tablet = "(min-width: 768px)"
medium = "(min-width: 992px)"
large = "(min-width: 1200px)"
tm = tablet + "," + medium
tml = tablet + "," + medium + "," large

bp(paramaters)
  @media paramaters
    {block}

font-url(file)
  return '../font/' + file

webfont(family, file, hack-chrome-windows = false, weight = '400')
  @font-face
    font-family family
    src url(font-url(file + '.eot'))
    src url(font-url(file + '.eot?#iefix')) format('embedded-opentype'),
        url(font-url(file + '.woff')) format('woff'), 
        url(font-url(file + '.ttf')) format('truetype'), 
        url(font-url(file + '.svg#'+ family)) format('svg')
    font-weight weight
    font-style normal
  if hack-chrome-windows
    @media screen and (-webkit-min-device-pixel-ratio:0)
      @font-face
        font-family family
        src url(font-url(file + '.svg#'+ family)) format('svg')

border-radius(n)
  -webkit-border-radius n
  -moz-border-radius n
  border-radius n
  
background-size(n)
  -webkit-background-size: n
  -moz-background-size: n
  -o-background-size: n
  background-size: n

clearfix()
  +ie(7, true)
    zoom 1

  &:before
  &:after
    content ""
    display table
  &:after
    clear both

animation(ani)
  -webkit-animation: ani
  -moz-animation: ani
  -ms-animation: ani
  -o-animation: ani
  animation: ani

animation-delay(ani)
  -webkit-animation-delay: ani
  -moz-animation-delay: ani
  -ms-animation-delay: ani
  -o-animation-delay: ani
  animation-delay: ani

animation-name(ani)
  -webkit-animation-name: ani
  -moz-animation-name: ani
  -ms-animation-name: ani
  -o-animation-name: ani
  animation-name: ani
  
animation-play-state(ani)
  -webkit-animation-play-state: ani
  -moz-animation-play-state: ani
  -ms-animation-play-state: ani
  -o-animation-play-state: ani
  animation-play-state: ani

animation-timing-function(ani)
  -webkit-animation-timing-function: ani
  -moz-animation-timing-function: ani
  -ms-animation-timing-function: ani
  -o-animation-timing-function: ani
  animation-timing-function: ani

transform(trans)  
  -webkit-transform: trans
  -ms-transform: trans
  -moz-transform: trans
  -o-transform: trans
  transform: trans

transform-origin(trans)
  -webkit-transform-origin: trans
  -ms-transform-origin: trans
  -o-transform-origin: trans
  -moz-transform-origin: trans
  transform-origin: trans
  
transition(vals)
  -webkit-transition: vals
  -ms-transition: vals 
  -o-transition: vals 
  -moz-transition: vals 
  transition: vals 
  
transition-property(vals)
  -webkit-transition-property: vals
  -ms-transition-property: vals 
  -o-transition-property: vals 
  -moz-transition-property: vals 
  transition-property: vals 

transition-duration(vals)
  -webkit-transition-duration: vals
  -ms-transition-duration: vals 
  -o-transition-duration: vals 
  -moz-transition-duration: vals 
  transition-duration: vals 

transition-timing-function(vals)
  -webkit-transition-timing-function: vals
  -ms-transition-timing-function: vals 
  -o-transition-timing-function: vals 
  -moz-transition-duration: vals 
  transition-duration: vals

filter(vals)
  -webkit-filter: vals
  -moz-filter: vals
  -o-filter: vals
  -ms-filter: vals
  filter: vals
  
  
placeholder()
  &::-webkit-input-placeholder
      {block}
  &:-moz-placeholder
      {block}
  &::-moz-placeholder
      {block}
  &:-ms-input-placeholder
      {block}














