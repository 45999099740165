@import bootstrap
@import 'mixins/*'
basecolor = #0087CF

webfont('avenir-bold', 'AvenirNext-Bold', yes, 400)
webfont('avenir-demibold', 'AvenirNext-DemiBold', yes, 400)
webfont('avenir-medium', 'AvenirNext-Medium', yes, 400)
body
	margin 0

//ust slider

#bir.container-fluid
	position relative
	padding 0
	height 561px //700
	overflow hidden
	background basecolor
	+bp(medium)
		height 550px

.sliderPost
	position absolute
	z-index 9000
	bottom 150px
	width 100%
	color #fff
	font-family "Avenir Next"
	font-size 25px
	left 20px
	opacity 0
	p
		margin 0
		padding 0
	+bp(medium)
		opacity 0
		pointer-events none

.sliderImg
	+bp(medium)
		max-height 100% !important

div.lSSlideOuter
	position relative
	height 330px
	&:after					
		content ''
		background url('../img/mobilSliderBlue.png')
		width 100%
		height 73px
		position absolute
		z-index 45
		bottom 0px
		left 0
		+bp(medium)
			display none

#topMenuDesktop
	position relative
	margin-top 50px
	z-index 20
	& .menuForDesktop 
		padding-top 17px
		& .col-md-12 .tabUldesktop li
			border-color transparent

.gokboaSliderContentZone
	position absolute
	width 874px
	height 731px
	top 0
	&:before
		+bp(medium)
			content ''
			background url('../img/mask.png')
			width 874px
			height 731px
			position absolute
			z-index 19
			top 0
			opacity .8
	&:after
		+bp(medium)
			content ''
			position absolute
			top 0
			right 0
			z-index 20
			width 307px
			height 731px
			background url('../img/kivrimust.png')

.gokboaSliderContentInitiate
	position relative
	z-index 20
	color #fff
	font-family "avenir-medium"
	font-size 25px
	top 330px
	left 20px
	& .sliderText p
		margin 0
	+bp(medium)
		top 80px
		left 32px

.numbers p
	margin 0
	float left
	padding 0 2px
.arrowbefore, .arrowafter
	width 12px
	height 34px
	background url("../img/arrow.png") no-repeat
	cursor pointer
	margin 0
	float left
	padding 0 10px

.arrowafter
	transform rotate(180deg)
.arrow
	padding-top 50px
.numbers
	margin 0
	float left
.lSSlideWrapper
	position relative
	&:after
		content ''
		background url('../img/sliderImgGradient.png')
		width 185px
		height 330px 
		position absolute
		right 0
		top 0
		z-index 30
		+bp(medium)
			display none
	&:before
		content ''
		background url('../img/sliderImgGradient.png')
		width 185px
		height 330px
		position absolute
		left 0
		top 0
		z-index 30
		transform rotateZ(180deg)
		+bp(medium)
			display none
div#ikidestek.container-fluid
	background basecolor
	position relative
	overflow hidden
	&:after
		+bp(medium)
			content ''
			position absolute
			z-index 1
			pointer-events none
			bottom 0
			left -52px
			width 100%
			border-bottom 150px solid #1d1d1d
			border-left 2500px solid transparent
			background url('../img/sliderL.png') center center no-repeat
			background-size cover
	+bp(medium)
		background url('../img/kurumsal_bg.png')
		height 765px
		margin-top -65px
		position relative
		z-index 20
		background-size cover
#ikidestek.container-fluid,
#iki.container
	padding 0
	min-height 300px

.mobilIcerikSec
	padding-left 20px
	li
		padding 10px 0
		a
			text-transform uppercase
			font-family "Avenir Next" 
			color #fff
			font-weight 600
			font-size 20px
		a.active
			font-weight bolder
.desktopIcerik, .menuDesktopContent
	+bp(medium)
		padding-top 100px

.menuForDesktop
	+bp(medium)
		padding-top 10px

.tabUldesktop
	li
		font-family "avenir-bold"
		font-size 15px
		float left
		padding 0 25px 5px 0
		border-bottom 2px solid #333
		a
			color #333
		&.active a
			border-bottom 2px solid #333
			padding 4px 0
	li:last-child
		padding 0 0 5px 0

.tabUldesktop.white
	li
		color #fff
		border-color #fff
		a
			color #fff
		&.active a
			border-bottom 2px solid #fff

.desktopTabMenu
	font-family "avenir-medium"
	font-size 21px
	color #fff
	text-transform uppercase
	li
		padding 10px 0
		+bp(medium)
			padding 0
		a
			color #fff
			+bp(medium)
				color #333
	+bp(medium)
		font-size 12px
		color #333
	li.active
		font-family "avenir-bold"

.content
	color #fff
	font-family "avenir-medium"
	+bp(medium)
		line-height 21px
		color #333
.sartabmenu
	+bp(medium)
		padding 16px

#ucdestek
	overflow hidden

#ucdestek.container-fluid,
#uc.container
	padding 0
	min-height 500px 
	position relative
	background-color #1d1d1d

#ucdestek:after
	content ''
	background-color #134c68
	width 100%
	height 46px
	position absolute
	z-index 0
	pointer-events none
	bottom -108px
	left 0
	border-bottom 150px solid #0E4864
	border-right 2000px solid transparent
	background url("../img/sliderL.png") center center no-repeat
	background-size cover
	+bp(medium)
		bottom -70px

#ucbottom
	width 100%

#ucbottom:before
	content ''
	position absolute
	z-index 1
	pointer-events none
	top -70px
	left 0
	width 100%
	border-top 150px solid basecolor
	border-right 1000px solid transparent
	background url('../img/sliderL.png') center center no-repeat
	background-size cover
	+bp(medium)
		display none
		//border-right 2500px solid transparent

#ucbottom:after
	content ''
	position absolute
	z-index 1
	pointer-events none
	bottom 0
	left 0
	width 100%
	border-bottom 150px solid basecolor
	border-left 1000px solid transparent
	background url('../img/sliderL.png') center center no-repeat
	background-size cover
	+bp(medium)
		border-left 2500px solid transparent

.ucicerik
	padding 100px 0 50px 0
	+bp(medium)
		padding 25px 0 50px 0

.timeline
	position relative					
	ul
		text-align center
		&:before, &:after
			content ''
			width 1px
			height 60px
			background basecolor
			position absolute
			z-index 0
		&:before
			top -33px
		&:after
			bottom -33px

		li
			position relative
			padding 40px 0
			color #fff
			z-index 1
			&:after
				content ''
				width 11px
				height 11px
				border-radius 35px
				border 3px solid basecolor
				background transparent
				box-shadow 0px 0px 1px #000
				position absolute
				bottom 86px
				left 50%
				margin-left -5px
			&:not(:first-child):before
				content ''
				width 1px
				height 60px
				background basecolor
				position absolute
				top -31px
				z-index -1
	.tarih
		color basecolor
		font-weight bolder
		font-size 11px
		padding 4px 0

.kilometre
	position relative
	z-index 1
	left -11px

div#bes.container-fluid
	padding 0
	height 1050px
	background url('../img/footer_bg.png')
	background-size cover
	background-position 50% 50%

#bottomMenu
	padding-top 50px

.carousel
	padding-top 150px

#pd0
	padding 0

.social
	padding-top 120px

.twitter, .facebook, .instagram
	width 37px
	height 37px
	display block
	float left
	margin-right 20px

.twitter
	background url('../img/@2twitter.png')
	background-size cover
.facebook
	background url('../img/@2facebook.png')
	background-size cover
.instagram
	background url('../img/@2instagram.png')
	background-size cover